<template>
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.56 17.56">
    <circle cx="7.11" cy="7.11" r="6.11"
            style="fill:none; stroke:currentColor; stroke-linecap:round; stroke-linejoin:round; stroke-width:2px;" />
    <line x1="11.43" y1="11.43" x2="16.56" y2="16.56"
          style="fill:none; stroke:currentColor; stroke-linecap:round; stroke-linejoin:round; stroke-width:2px;" />
  </svg>
</template>

<style scoped>

</style>
